<template>
  <div class="butter-content" v-if="childDataLoaded">
    <div class="back-button">
      <router-link :to="{ name: 'blog' }" class="text-link-button">
        <img src="https://res.cloudinary.com/healthytogether/image/upload/v1618503847/htio/5f6d3c6d12d940a546f82284_back-button-arrow.svg"  alt="">
        <span>{{ $t('back') }}</span>
      </router-link>
    </div>
    <div class="page-container">
      <template v-if="page && page.fields">
        <div class="page-header-wrapper centered">
          <p class="page-category">{{ page.fields.categories}}</p>
          <p class="page-title-headline">{{ page.fields.title }}</p>
          <p class="page-header">{{ page.fields.post_header }}</p>
          <p class="page-read-time">{{ page.fields.read_time }} {{ $t('readTime') }}</p>

        </div>
        <p class="image-container">
          <img :src="page.fields.banner_image" alt="" srcset="">
        </p>
        <p class="page-photo-description">{{ page.fields.banner_image_description }}</p>
        <div class="page-header-wrapper centered">
          <p class="wysiwyg" v-html="page.fields.content"></p>
          <p class="page-author">{{ getAuthorFullNameBySlug(page.fields.author) }}</p>
        </div>

        <div class="register-banner-container">
          <a :href="demoFormUrl" target="_blank">
            <img :src="bannerUrl" alt="">
          </a>
        </div>

      </template>
    </div>
  </div>
</template>

<script>

import {
  butter
} from '@/buttercms';

export default {
  name: 'BlogPost',
  data() {
    return {
      title: 'BlogPost',
      slug: this.$route.params.slug,
      authors: [],
      page: {
        slug: '',
        fields: {},
      },
      viewPortWidth: 0,
      childDataLoaded: false,
    };
  },
  computed: {
    demoFormUrl() {
      const demoRoute = {
        name: 'enterprise',
        query: { target: 'submitContactForm' },
      };
      return this.$router.resolve(demoRoute).href;
    },
    bannerUrl() {
      if(this.viewPortWidth > 768) {
        return 'https://res.cloudinary.com/healthytogether/image/upload/v1618344100/htio/5f73ae70d227dbe2d8b0148d_business-free-ad-img.png';
      }
      return 'https://res.cloudinary.com/healthytogether/image/upload/v1618509407/htio/5f73ae811efdaa6cd20c56ad_business-free-ad-mobile-img.png';
    },
  },
  methods: {
    getAuthorFullNameBySlug(slug) {
      const author = this.authors.find((x) => x.slug === slug);
      return author ? `${author.first_name} ${author.last_name}` : '';
    },
    fetchAuthors() {
      butter.author.list()
        .then((res) => {
          this.authors = res.data.data;
        // console.log(this.authors);
        });
    },
    fetchPage() {
      const locale = this.$route.params.locale;
      butter.page.retrieve('blog', this.$route.params.slug, { locale: locale })
        .then((res) => {
          this.page = res.data.data;
          this.childDataLoaded = true;
        })
        .catch((e) => {
          console.warn(e);
          this.$router.push({ name: 'blog' });
        });
    },
    calculateViewportWidth() {
      this.viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    },
  },
  created() {
    window.addEventListener("resize", this.calculateViewportWidth);
    this.calculateViewportWidth();
    this.fetchAuthors();
    this.fetchPage();
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateViewportWidth);
  },
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
}
.butter-content {
  overflow: auto;
  text-align: left;
  img {
    width: 100%;
  }
}
.back-button {
  margin: 0 0 1.4rem;
  a {
    display: flex;
    align-items: center;
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.page-list-item {
  display: flex;
  flex-basis: 50%;
  margin: 0 0 1.5rem;
  a {
    text-decoration: none;
    color: #161616;
  }
}
.latest-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.latest-pages-container {
  display: flex;
  flex-wrap: wrap;
}
.page-container {
  a {
    text-decoration: none;
    color: #161616;
  }
}
.image-container {
  margin: 3rem 0 0;
}
.page-title {
  max-width: 90%;
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 300;
}
.page-header {
  color: #505050;
}
.page-header-wrapper {
    width: 60%;
    &.centered {
      margin: auto;
    }
}
.page-title-headline {
  font-size: 3.5rem;
  line-height: 1.2em;
  margin: 0 0 0.875rem;
}
.page-category {
  font-weight: 700;
  text-transform: uppercase;
  color: #3c8dff;
  font-size: 0.875rem;
  margin: 0;
}
.page-author {
  color: #505050;
  font-size: 0.875rem;
  font-weight: 700;
}
.page-read-time {
  margin-bottom: 0;
  color: #505050;
  font-size: 0.75rem;
  font-weight: 700;
}
.page-photo-description {
  margin-bottom: 3rem;
  font-style: italic;
}
@include bp-xsmall {
  .back-button {
  display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    padding: 2rem 0 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 1rem 0;
    }
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 1.4rem;
  }
  .register-banner-container {
    margin: 2rem 0;
  }
}
@include bp-small {
  .back-button {
    display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    padding: 2rem 0 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 1rem 0;
    }
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 1.4rem;
  }
  .register-banner-container {
    margin: 2rem 0;
  }
}
@include bp-medium {
  .back-button {
    display: none;
  }
  .butter-content {
    padding: 2rem 0rem 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 0;
    }
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 4rem;
  }
  .register-banner-container {
    margin: 4rem auto;
  }
}
@include bp-large {
 .page-header-wrapper {
    width: 60%;
    &.centered {
      margin: auto;
    }
  }
}
.image-container {
  margin: 48px 0 0;
}
.page-title {
  max-width: 90%;
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 300;
}
.page-header {
  color: #505050;
}
.page-header-wrapper {
    width: 60%;
    &.centered {
      margin: auto;
    }
}
.page-title-headline {
  font-size: 3.5rem;
  line-height: 1.2em;
  margin: 0 0 0.875rem;
}
.page-category {
  font-weight: 700;
  text-transform: uppercase;
  color: #3c8dff;
  font-size: 0.875rem;
  margin: 0;
}
.page-author {
  color: #505050;
  font-size: 0.875rem;
  font-weight: 700;
}
.page-read-time {
  margin-bottom: 0;
  color: #505050;
  font-size: 0.75rem;
  font-weight: 700;
}
.page-photo-description {
  margin-bottom: 3rem;
  font-style: italic;
}
@include bp-xsmall {
  .back-button {
  display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    padding: 2rem 0 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 1rem;
    }
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 1.4rem;
  }
  .register-banner-container {
    margin: 2rem 0;
  }
}
@include bp-small {
  .back-button {
    display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    padding: 2rem 0 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 1rem 0;
    }
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 1.4rem;
  }
  .register-banner-container {
    margin: 2rem 0;
  }
}
@include bp-medium {
  .back-button {
    display: none;
  }
  .butter-content {
    padding: 2rem 0rem 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    &.centered {
      margin: 0;
    }
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .page-photo-description {
    margin: 1rem 0rem 4rem;
  }
  .register-banner-container {
    margin: 4rem auto;
  }
}
@include bp-large {
 .page-header-wrapper {
    width: 60%;
    &.centered {
      margin: auto;
    }
  }
}
</style>
